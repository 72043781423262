import React from 'react'
import { render } from 'react-dom'

class PortfolioComponent extends React.Component{


render(){
return(

    <>

<section  id="portfolio"  className="portfolio">
                <div className="section-inner start-page-full-width">
                <div className="start-page-full-width">
<h1>Portfolio</h1>
<section className="portfolio-content">	



<section className="portfolio-section">


    <ul className="portfolio-area">	

        <li className="portfolio-item portfolio-area__new-row" >	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                  
                      <header className="browser-interface clearfix">
                          
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb1.png" className="brand-img" alt="theincircle" />
                    </div>
                    <div className="mask">
                      <h2>theincircle.com</h2>
                      <p>Blue Color Job Portal <br/>
                      <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b> Photoshop, Html/CSS, Javascript, Bootstrap, ReactJS
                      </p>
                      
                      <a href="https://www.theincircle.com/" target="_blank" className="info">Live Portal <i className="fa fa-go"></i></a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.theincircle.com/" target="_blank" rel="bookmark" title="Vrn-Navigator">Theincircle</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2021-03-01</p>
                </div>
            </div>	
        </li>
         
        <li className="portfolio-item" data-type="cat-item-4">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                      
                      <header className="browser-interface clearfix">

                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb2.png" className="brand-img" alt="Employerzone" />
                    </div>
                    <div className="mask">
                      <h2>Employerzone</h2>
                      <p> Recruiter Page<br/><b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b> Photoshop, Html/CSS, javascript, Bootstrap, VUE</p>
                      <a href="https://www.theincircle.com/employerzone" target="_blank" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.theincircle.com/employerzone" target="_blank" rel="bookmark" title="PIANO-MUSIC.PRO">Theincircle Employer</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2021-02-01</p>
                </div>
            </div>	
        </li>			
                            
        <li className="portfolio-item" data-type="cat-item-2">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                      
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb3.png" className="brand-img" alt="CheapOair" />
                    </div>
                    <div className="mask">
                      <h2>CheapOair Flights</h2>
                      <p>Travel Portal (USA)<br/>
                      <b>My role:</b>Pulling Deals with Rest API<br/>
                      <b>Skills:</b> Html/CSS, Javascript, Bootstrap
                      </p>
                      <a href="https://www.cheapoair.com/flights" target="_blank" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a target="_blank" href="https://www.cheapoair.com/flights" rel="bookmark" title="CheapOair Flights">CheapOair Flights</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2020-01-13</p>
                </div>
            </div>	
        </li>
                            
        <li className="portfolio-item portfolio-area__new-row" data-type="cat-item-2">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                     
                      <header className="browser-interface clearfix">
                          
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb4.png" className="brand-img" alt="AltText: Image from the portfolio" />
                    </div>
                    <div className="mask">
                      <h2>Miorola jewellery</h2>
                      <p>Online jewellery Shope<br/>
                      <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b>Photoshop,  Html/CSS, Javascript, Bootstrap, OpenCart
                      </p>
                      <a href="https://www.miorola.com/" className="info">More detailed</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.miorola.com/" rel="bookmark" title="Miorola jewellery">Miorola jewellery</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2021-01-26</p>
                </div>
            </div>	
        </li>			        				
                 
           <li className="portfolio-item" data-type="cat-item-1">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                      
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb5.png" className="brand-img" alt="AltText: Image from the portfolio" />
                    </div>
                    <div className="mask">
                    <h2>CheapOair.ca Flights</h2>
                      <p>Travel Portal (CA)<br/>
                      <b>My role:</b>Pulling Deals with Rest API<br/>
                      <b>Skills:</b> Html/CSS, Javascript, Bootstrap
                      </p>
                      <a href="https://www.cheapoair.ca/flights" target="_blank" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a target="_blank" href="https://www.cheapoair.ca/flights" rel="bookmark" title="CheapOairCA Flights">CheapOair.ca Flights</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2020-03-15</p>
                </div>
            </div>	
        </li>		
                            
        <li className="portfolio-item" data-type="cat-item-3">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                      
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb6.png" className="brand-img" alt="Candid Software" />
                    </div>
                    <div className="mask">
                      <h2>Candid Software</h2>
                      <p>Web Development Company India<br/>
                      <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b>Photoshop,  Html/CSS, Javascript
                      </p>
                      <a href="http://www.candid.in/" target="_blank" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="http://www.candid.in/" target="_blank" rel="bookmark" title="Candid Software">Candid Software</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2007-07-11</p>
                </div>
            </div>	
        </li>			
                            
        <li className="portfolio-item portfolio-area__new-row" data-type="cat-item-2">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                      
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb7.png" className="brand-img" alt="AltText: Image from the portfolio" />
                    </div>
                    <div className="mask">
                      <h2>TERI University</h2>
                      <p>University Portal <br/>
                      <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b>Photoshop,  Html/CSS, Javascript
                      </p>
                      <a href="https://www.terisas.ac.in/" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.terisas.ac.in/" rel="bookmark" title="TERI University">TERI University</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2012-02-17</p>
                </div>
            </div>	
        </li>

        <li className="portfolio-item" data-type="cat-item-2">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                  
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb8.png" className="brand-img" alt="The Energy and Resources Institute" />
                    </div>
                    <div className="mask">
                      <h2>The Energy and Resources Institute</h2>
                      <p> <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b>Photoshop,  Html/CSS, Javascript</p>
                      <a href="https://www.teriin.org/" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.teriin.org/" rel="bookmark" title="The Energy and Resources Institute">TERI</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2012-05-12</p>
                </div>
            </div>	
        </li>			

                
        <li className="portfolio-item" data-type="cat-item-2">	
            <div className="main-portfolio-grid clearfix">
                <figure className="view view-portfolio">
                  
                      <header className="browser-interface clearfix">
                          
                      </header>
                      <div className="view-wrapper">
                        <img src="/images/web-thumb9.png" className="brand-img" alt="The Energy and Resources Institute" />
                    </div>
                    <div className="mask">
                      <h2>GRIHA</h2>
                      <p> <b>My role:</b>Designed and developed it.<br/>
                      <b>Skills:</b>Photoshop,  Html/CSS, Javascript</p>
                      <a href="https://www.grihaindia.org/" className="info">Live Portal</a>
                    </div>
                </figure>
                <div className="home-portfolio-text">
                    <h2 className="post-title-portfolio"><a href="https://www.grihaindia.org/" rel="bookmark" title="GRIHA">GRIHA</a></h2>
                    <p className="post-subtitle-portfolio">Published: 2011-04-11</p>
                </div>
            </div>	
        </li>		            
        
    </ul>
</section>
</section>
</div> 
                </div> 
               
                
            </section>

</>
)

}

}
export default PortfolioComponent